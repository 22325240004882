@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Colors */
:root{
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: .6rem 1.5rem;
  background: var(--gradient-color);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a {
  color: var(--white-color);
  font-weight: 500;
}

.btn:hover {
  background: var(--secondary-color);
}

img, video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
}

body {
  background: var(--body-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 1024px) {
  .container {
    width: 80% !important;
    margin: auto !important;
  }

  .section {
    padding: 6rem 0 4rem;
  }
}

@media screen and (min-width: 2560px) {
  body {
     zoom: 1.7;
  }
}