@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Colors */
:root{
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: .6rem 1.5rem;
  background: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  background: var(--gradient-color);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a {
  color: #FFF;
  color: var(--white-color);
  font-weight: 500;
}

.btn:hover {
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
}

img, video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
}

body {
  background: rgb(240, 240, 246);
  background: var(--body-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 1024px) {
  .container {
    width: 80% !important;
    margin: auto !important;
  }

  .section {
    padding: 6rem 0 4rem;
  }
}

@media screen and (min-width: 2560px) {
  body {
     zoom: 1.7;
  }
}
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.home {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.home .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.home video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.home .homeContent {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.home .homeContent .textDiv {
  padding: 2rem 0;
}
.home .homeContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.home .homeContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.home .homeContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.home .homeContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.home .homeContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .home .homeContent .textDiv .smallText {
    font-size: 16px;
  }
  .home .homeContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .home .homeContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .home .homeContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .home .homeContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 940px) {
  .home {
    height: 85vh;
  }
}
@media screen and (min-width: 1024px) {
  .home {
    height: 80vh;
  }
  .home .homeContent {
    padding-left: 0;
    padding-right: 0;
  }
  .home .homeContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .home .homeContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .home .homeContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .home .homeContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=home.css.map */
.cardDiv {
  position: relative;
  padding: 2rem 1rem;
  background: var(--white-color);
  border-radius: 10px;
  grid-gap: 1rem;
  gap: 1rem;
}
.cardDiv label {
  display: block;
  color: var(--text-color);
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
.cardDiv .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--input-color);
  border-radius: 3rem;
}
.cardDiv .input input {
  color: rgb(129, 129, 129);
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
.cardDiv .input input::-webkit-input-placeholder {
  font-size: 13px;
}
.cardDiv .input input::placeholder {
  font-size: 13px;
}
.cardDiv .input .icon {
  font-size: 18px;
  color: var(--primary-color) !important;
  margin-left: 10px;
}
.cardDiv .input .input[type=range] {
  background: blue;
}
.cardDiv .searchOptions {
  position: absolute;
  background: var(--gradient-color);
  width: -webkit-max-content;
  width: max-content;
  bottom: -0.7rem;
  -moz-column-gap: 0.8rem;
       grid-column-gap: 0.8rem;
       -webkit-column-gap: 0.8rem;
               column-gap: 0.8rem;
  padding: 5px 1rem;
  border-radius: 3rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  justify-content: center;
  cursor: pointer;
}
.cardDiv .searchOptions:hover {
  background: var(--primary-color);
}
.cardDiv .searchOptions .icon {
  font-size: 18px;
}
.cardDiv .searchOptions button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: var(--white-color);
  font-weight: 500;
}/*# sourceMappingURL=contactus.css.map */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
  --card-hover: rgb(255, 255, 255);
}

.main {
  width: 100%;
}
.main .secTitle .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.main .secTitle .title:after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 120px;
  right: 0;
  bottom: 5px;
  z-index: -1;
}
.main .secContent {
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.main .secContent .singleDestination {
  height: 100%;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: rgb(225, 225, 235);
  background: var(--card-background);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
}
.main .secContent .singleDestination:hover {
  background-color: rgb(255, 255, 255);
  background-color: var(--card-hover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.main .secContent .singleDestination .imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.main .secContent .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.3s ease;
}
.main .secContent .singleDestination .imageDiv:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.main .secContent .singleDestination .cardInfo {
  padding: 1rem;
}
.main .secContent .singleDestination .cardInfo .destTitle {
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  font-size: 1.25rem;
}
.main .secContent .singleDestination .cardInfo .continent {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.main .secContent .singleDestination .cardInfo .continent .icon {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-size: 18px;
}
.main .secContent .singleDestination .cardInfo .continent .name {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-weight: 400;
  font-size: 14px;
}
.main .secContent .singleDestination .cardInfo .fees {
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid rgb(190, 190, 190);
  border-top: 1.5px solid var(--gray-text);
  border-bottom: 1.5px solid rgb(190, 190, 190);
  border-bottom: 1.5px solid var(--gray-text);
}
.main .secContent .singleDestination .cardInfo .fees .grade {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  max-width: 100px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main .secContent .singleDestination .cardInfo .fees .grade small {
  text-align: center;
  background: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  background: var(--gradient-color);
  border-radius: 1rem;
  color: #FFF;
  color: var(--white-color);
  padding: 1px 10px;
  font-size: 10px;
}
.main .secContent .singleDestination .cardInfo .fees .price {
  font-size: 2rem;
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
}
.main .secContent .singleDestination .cardInfo .desc {
  font-size: 13.5px;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-weight: 500;
}
.main .secContent .singleDestination .cardInfo .btn {
  margin-top: 1rem;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.main .secContent .singleDestination .cardInfo .btn .icon {
  align-self: center;
  font-size: 18px;
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .title {
    font-size: 1.5rem;
  }
  .title::after {
    width: 155px !important;
  }
  .secContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .secContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}/*# sourceMappingURL=main.css.map */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
  --rating: #d8b800;
}

.starTray {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.starTray .icon {
  color: #d8b800;
  color: var(--rating);
  font-size: 1.5rem;
}/*# sourceMappingURL=rating.css.map */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.singleFeedback {
  height: 100%;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: rgb(225, 225, 235);
  background: var(--card-background);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
  padding: 1rem;
}
.singleFeedback:hover {
  background-color: var(--card-hover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.1s ease;
}
.singleFeedback .feedbackInfo {
  display: flex;
  flex-direction: column;
  border-bottom: 1.5px solid rgb(190, 190, 190);
  border-bottom: 1.5px solid var(--gray-text);
  padding-bottom: 1rem;
  font-size: 13.5px;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-weight: 500;
}
.singleFeedback .feedbackInfo .date {
  margin-left: auto;
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       grid-column-gap: 0.5rem;
       -webkit-column-gap: 0.5rem;
               column-gap: 0.5rem;
}
.singleFeedback .feedbackInfo .date .icon {
  font-size: 1rem;
}
.singleFeedback .personDetails {
  display: flex;
  margin-top: 0.5rem;
}
.singleFeedback .personDetails img {
  border: #FFF 2px solid;
  border: var(--white-color) 2px solid;
  border-radius: 6rem;
  width: 4.2rem;
  height: 4.2rem;
  text-align: center;
}
.singleFeedback .personDetails .details {
  margin-left: 1rem;
}
.singleFeedback .personDetails .details h4 {
  font-size: 1rem;
  font-weight: 500;
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
}
.singleFeedback .personDetails .details .country {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.2rem;
       grid-column-gap: 0.2rem;
       -webkit-column-gap: 0.2rem;
               column-gap: 0.2rem;
}
.singleFeedback .personDetails .details .country .icon {
  font-size: 0.8rem;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}
.singleFeedback .personDetails .details .country p {
  font-size: 0.8rem;
  font-weight: 400;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}/*# sourceMappingURL=feedback.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 65px;
  height: 465px;
}
.packageDetailHeader .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.packageDetailHeader video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader .description {
  padding: 30px 0;
}
.packageDetailHeader .headerContent {
  position: absolute;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.packageDetailHeader .headerContent .textDiv {
  padding: 2rem 0;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
}
.packageDetailHeader .headerContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.packageDetailHeader .headerContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.packageDetailHeader .headerContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .header .headerContent .textDiv .smallText {
    font-size: 16px;
  }
  .header .headerContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .header .headerContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .header .headerContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .header .headerContent {
    padding-left: 0;
    padding-right: 0;
  }
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .header .headerContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .header .headerContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .header .headerContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=header.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailBody .container {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
}
.packageDetailBody .container .bodyHeader {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
.packageDetailBody .container .packageIncludes {
  margin-bottom: 3rem;
}
.packageDetailBody .container .packageIncludes .packageIncludesTitle {
  display: flex;
  -moz-column-gap: 1rem;
       grid-column-gap: 1rem;
       -webkit-column-gap: 1rem;
               column-gap: 1rem;
  font-size: 20px;
  font-weight: 600;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin-bottom: 1rem;
}
.packageDetailBody .container .packageIncludes .packageIncludesTitle .icon {
  font-size: 25px;
}
.packageDetailBody .container .packageIncludes .packageIncludesTitle::after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 100px;
  left: 120px;
  top: 23px;
  z-index: -1;
}
.packageDetailBody .container .packageIncludes .packageIncludeDesc {
  line-height: 1.5;
  text-align: justify;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  padding: 0 2rem;
}
.packageDetailBody .container .packageIncludes .packageIncludeDesc li {
  list-style: disc !important;
}
.packageDetailBody .container .destinationRouting {
  display: flex;
  flex-direction: column;
}
.packageDetailBody .container .destinationRouting .destinationRoutingTitle {
  display: flex;
  -moz-column-gap: 1rem;
       grid-column-gap: 1rem;
       -webkit-column-gap: 1rem;
               column-gap: 1rem;
  font-size: 20px;
  font-weight: 600;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin-bottom: 1rem;
}
.packageDetailBody .container .destinationRouting .destinationRoutingTitle .icon {
  font-size: 25px;
}
.packageDetailBody .container .destinationRouting .destinationRoutingTitle::after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 100px;
  left: 62px;
  bottom: 2px;
  z-index: -1;
}
.packageDetailBody .container .destinationRouting .dailyRouting .dailyRoutingItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(190, 190, 190);
  border-bottom: 1px solid var(--gray-text);
  padding: 1rem 0;
}
.packageDetailBody .container .destinationRouting .dailyRouting .dailyRoutingItem .dailyRoutingItemNames {
  padding-right: 1rem;
}
.packageDetailBody .container .destinationRouting .dailyRouting .dailyRoutingItem .dailyRoutingItemNames .dailyRoutingItemName {
  font-size: 16px;
  font-weight: 600;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}
.packageDetailBody .container .destinationRouting .dailyRouting .dailyRoutingItem .dailyRoutingItemNames .dailyRoutingItemName small {
  font-size: 14px;
  font-weight: 400;
  color: rgb(190, 190, 190);
  color: var(--gray-text);
}
.packageDetailBody .container .destinationRouting .dailyRouting .dailyRoutingItem .dailyRoutingItemDesc {
  text-align: right;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}

@media screen and (min-width: 375px) {
  .packageDetailBody .container .destinationRouting .dailyRoutingItem {
    padding: 1rem 2rem;
  }
}/*# sourceMappingURL=body.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.booknowBody .container {
  display: flex;
  flex-direction: column;
  padding: 5rem 2rem;
}
.booknowBody .container .secTitle .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.booknowBody .container .secTitle .title:after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 95px !important;
  right: 0;
  bottom: 5px;
  z-index: -1;
}
.booknowBody .container .booknowForm {
  padding: 0.2rem 1rem;
  background: #FFF;
  background: var(--white-color);
  border-radius: 10px;
  grid-gap: 1rem;
  gap: 1rem;
}
.booknowBody .container .booknowForm label {
  display: block;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}
.booknowBody .container .booknowForm .input {
  height: 40px;
  padding: 0 1rem;
  background: rgb(229, 229, 229);
  background: var(--input-color);
  border-radius: 3rem;
}
.booknowBody .container .booknowForm .input input, .booknowBody .container .booknowForm .input select, .booknowBody .container .booknowForm .input textarea {
  color: rgb(129, 129, 129);
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
.booknowBody .container .booknowForm .input input::-webkit-input-placeholder, .booknowBody .container .booknowForm .input select::-webkit-input-placeholder, .booknowBody .container .booknowForm .input textarea::-webkit-input-placeholder {
  font-size: 13px;
}
.booknowBody .container .booknowForm .input input::placeholder, .booknowBody .container .booknowForm .input select::placeholder, .booknowBody .container .booknowForm .input textarea::placeholder {
  font-size: 13px;
}
.booknowBody .container .booknowForm .input .icon {
  font-size: 18px;
  color: hsl(199, 100%, 33%) !important;
  color: var(--primary-color) !important;
  margin-left: 10px;
}
.booknowBody .container .booknowForm .message {
  padding: 1rem 1rem !important;
  height: 7rem !important;
  border-radius: 0.7rem;
}
.booknowBody .container .booknowForm .message textarea {
  resize: none;
  height: 100%;
  width: 100%;
}
.booknowBody .container .booknowForm .btn {
  margin: 1rem auto;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.booknowBody .container .booknowForm .btn a {
  display: flex;
  -moz-column-gap: 7px;
       grid-column-gap: 7px;
       -webkit-column-gap: 7px;
               column-gap: 7px;
}
.booknowBody .container .booknowForm .btn .icon {
  align-self: center;
  font-size: 18px;
}

@media screen and (min-width: 660px) {
  .booknowBody .booknowForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .booknowBody .booknowForm .concrensInput, .booknowBody .booknowForm .btn {
    grid-column: 1/3;
  }
}/*# sourceMappingURL=body.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 65px;
  height: 465px;
}
.packageDetailHeader .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.packageDetailHeader video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader .description {
  padding: 30px 0;
}
.packageDetailHeader .headerContent {
  position: absolute;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.packageDetailHeader .headerContent .textDiv {
  padding: 2rem 0;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
}
.packageDetailHeader .headerContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.packageDetailHeader .headerContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.packageDetailHeader .headerContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .header .headerContent .textDiv .smallText {
    font-size: 16px;
  }
  .header .headerContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .header .headerContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .header .headerContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .header .headerContent {
    padding-left: 0;
    padding-right: 0;
  }
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .header .headerContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .header .headerContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .header .headerContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=header.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.placesBody .container {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
}
.placesBody .container .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.placesBody .container .title:after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 55px !important;
  right: -8px;
  bottom: 5px;
  z-index: -1;
}
.placesBody .container .description {
  display: flex;
  flex-direction: column;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}
.placesBody .container .description .imageContainer {
  width: 100%;
  height: auto;
}
.placesBody .container .description .textContainer {
  text-align: justify;
}

@media screen and (min-width: 426px) {
  .placesBody .container .description {
    flex-direction: row;
    -moz-column-gap: 2rem;
         grid-column-gap: 2rem;
         -webkit-column-gap: 2rem;
                 column-gap: 2rem;
    align-items: center;
  }
  .placesBody .container .description .imageContainer {
    width: 160rem;
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  .placesBody .container .description .imageContainer {
    width: 60rem;
    height: auto;
  }
}/*# sourceMappingURL=body.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 65px;
  height: 465px;
}
.packageDetailHeader .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.packageDetailHeader video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader .description {
  padding: 30px 0;
}
.packageDetailHeader .headerContent {
  position: absolute;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.packageDetailHeader .headerContent .textDiv {
  padding: 2rem 0;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
}
.packageDetailHeader .headerContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.packageDetailHeader .headerContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.packageDetailHeader .headerContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .header .headerContent .textDiv .smallText {
    font-size: 16px;
  }
  .header .headerContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .header .headerContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .header .headerContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .header .headerContent {
    padding-left: 0;
    padding-right: 0;
  }
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .header .headerContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .header .headerContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .header .headerContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=header.css.map */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
  --card-hover: rgb(255, 255, 255);
}

.cardList {
  width: 100%;
  padding: 0 0 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.cardList .secTitle .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.cardList .secTitle .title:after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 55px !important;
  right: 35px;
  bottom: 5px;
  z-index: -1;
}
.cardList .secContent {
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.cardList .secContent .singleDestination {
  height: 100%;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: rgb(225, 225, 235);
  background: var(--card-background);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
}
.cardList .secContent .singleDestination:hover {
  background-color: rgb(255, 255, 255);
  background-color: var(--card-hover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.cardList .secContent .singleDestination .imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.cardList .secContent .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.3s ease;
}
.cardList .secContent .singleDestination .imageDiv:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.cardList .secContent .singleDestination .cardInfo {
  padding: 1rem;
}
.cardList .secContent .singleDestination .cardInfo .destTitle {
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  font-size: 1.25rem;
}
.cardList .secContent .singleDestination .cardInfo .continent {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.cardList .secContent .singleDestination .cardInfo .continent .icon {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-size: 18px;
}
.cardList .secContent .singleDestination .cardInfo .continent .name {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-weight: 400;
  font-size: 14px;
}
.cardList .secContent .singleDestination .cardInfo .desc {
  font-size: 13.5px;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-weight: 500;
  text-align: justify;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid rgb(190, 190, 190);
  border-top: 1.5px solid var(--gray-text);
}
.cardList .secContent .singleDestination .cardInfo .center {
  display: flex;
  width: 100%;
  justify-content: center;
}
.cardList .secContent .singleDestination .cardInfo .center .btn {
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.cardList .secContent .singleDestination .cardInfo .center .btn .icon {
  align-self: center;
  font-size: 18px;
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .title {
    font-size: 1.5rem;
  }
  .title::after {
    width: 155px !important;
  }
  .secContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .secContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}/*# sourceMappingURL=card.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 65px;
  height: 465px;
}
.packageDetailHeader .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.packageDetailHeader video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader .description {
  padding: 30px 0;
}
.packageDetailHeader .headerContent {
  position: absolute;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.packageDetailHeader .headerContent .textDiv {
  padding: 2rem 0;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
}
.packageDetailHeader .headerContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.packageDetailHeader .headerContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.packageDetailHeader .headerContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .header .headerContent .textDiv .smallText {
    font-size: 16px;
  }
  .header .headerContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .header .headerContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .header .headerContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .header .headerContent {
    padding-left: 0;
    padding-right: 0;
  }
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .header .headerContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .header .headerContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .header .headerContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=header.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.placeDetailBody .container {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
}
.placeDetailBody .container .secTitle .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.placeDetailBody .container .secTitle .title:after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 70px !important;
  right: 0;
  bottom: 5px;
  z-index: -1;
}
.placeDetailBody .container .row {
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgb(190, 190, 190);
  border-bottom: 3px solid var(--gray-text);
  padding-bottom: 2rem;
}
.placeDetailBody .container .row .imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.placeDetailBody .container .row .imageContainer img {
  border-radius: 1rem;
  width: 100%;
}
.placeDetailBody .container .textContainer {
  text-align: justify;
  margin: 1rem 0;
}

@media screen and (min-width: 768px) {
  .placeDetailBody .container .row .imageContainer img {
    border-radius: 1rem;
    width: 80%;
  }
}/*# sourceMappingURL=body.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.aboutUsBody .container {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
}
.aboutUsBody .container .discription {
  margin-bottom: 2rem;
  text-align: justify;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}
.aboutUsBody .container .contactUs .contactUsHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem 1rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  text-align: center;
  font-size: 12px;
  color: #FFF;
  color: var(--white-color);
  background: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  background: var(--gradient-color);
}
.aboutUsBody .container .contactUs .contactUsHeader .email, .aboutUsBody .container .contactUs .contactUsHeader .call {
  color: #FFF;
  color: var(--white-color);
}
.aboutUsBody .container .contactUs .contactUsHeader .email h3, .aboutUsBody .container .contactUs .contactUsHeader .call h3 {
  display: flex;
  -moz-column-gap: 10px;
       grid-column-gap: 10px;
       -webkit-column-gap: 10px;
               column-gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.aboutUsBody .container .contactUs .contactUsHeader .line {
  width: 100px;
  height: 1px;
  background-color: #FFF;
  background-color: var(--white-color);
}
.aboutUsBody .container .deals {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
}
.aboutUsBody .container .deals .deal {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       grid-column-gap: 1rem;
       -webkit-column-gap: 1rem;
               column-gap: 1rem;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  font-size: 13px;
}
.aboutUsBody .container .deals .deal .icon {
  border-radius: 7px;
  height: 70px;
  width: 70px;
  color: #FFF;
  color: var(--white-color);
  background: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  background: var(--gradient-color);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
.aboutUsBody .container .deals .deal svg {
  font-size: 3rem;
}
.aboutUsBody .container .follow-us .title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  margin: 1rem 0;
  z-index: 2;
}
.aboutUsBody .container .follow-us .title::after {
  position: absolute;
  content: "";
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  height: 5px;
  width: 100px !important;
  right: -10px;
  top: 24px;
  z-index: -1;
}
.aboutUsBody .container .follow-us .iconsTray {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 0.5rem;
       grid-column-gap: 0.5rem;
       -webkit-column-gap: 0.5rem;
               column-gap: 0.5rem;
  font-size: 15px;
  align-items: center;
  justify-content: center;
}
.aboutUsBody .container .follow-us .iconsTray .facebook, .aboutUsBody .container .follow-us .iconsTray .insta, .aboutUsBody .container .follow-us .iconsTray .whatsapp, .aboutUsBody .container .follow-us .iconsTray .tripadvisor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}
.aboutUsBody .container .follow-us .iconsTray .facebook a, .aboutUsBody .container .follow-us .iconsTray .insta a, .aboutUsBody .container .follow-us .iconsTray .whatsapp a, .aboutUsBody .container .follow-us .iconsTray .tripadvisor a {
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}

@media screen and (min-width: 375px) {
  .packageDetailBody .container .destinationRouting .dailyRoutingItem {
    padding: 1rem 2rem;
  }
}
@media screen and (min-width: 600px) {
  .aboutUsBody .container .deals {
    grid-template-columns: repeat(2, 1fr);
  }
  .aboutUsBody .container .follow-us .iconsTray {
    grid-template-columns: repeat(4, 1fr);
    font-size: 17px;
  }
}
@media screen and (min-width: 850px) {
  .aboutUsBody .container .contactUs .contactUsHeader {
    flex-direction: row;
    padding: 1rem 10rem;
    font-size: 16px;
  }
  .aboutUsBody .container .contactUs .contactUsHeader .line {
    width: 1px;
    height: 50px;
  }
  .aboutUsBody .container .deals {
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=body.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.packageDetailHeader {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 65px;
  height: 465px;
}
.packageDetailHeader .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(47, 106, 127);
  mix-blend-mode: hard-light;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.packageDetailHeader video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.packageDetailHeader .description {
  padding: 30px 0;
}
.packageDetailHeader .headerContent {
  position: absolute;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: #FFF;
  color: var(--white-color);
  z-index: 100;
}
.packageDetailHeader .headerContent .textDiv {
  padding: 2rem 0;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
}
.packageDetailHeader .headerContent .textDiv .smallText {
  font-size: 13px;
  text-transform: uppercase;
}
.packageDetailHeader .headerContent .textDiv .homeTitle {
  position: relative;
  font-size: 1.8rem;
}
.packageDetailHeader .headerContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #FFF;
  color: var(--white-color);
  font-weight: 600;
}
.packageDetailHeader .headerContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 768px) {
  .header .headerContent .textDiv .smallText {
    font-size: 16px;
  }
  .header .headerContent .textDiv .homeTitle {
    position: relative;
    font-size: 2.3rem;
    width: -webkit-max-content;
    width: max-content;
  }
  .header .headerContent .textDiv .homeTitle::after {
    position: absolute;
    content: "";
    background: hsl(187, 85%, 43%);
    background: var(--secondary-color);
    height: 5px;
    width: 145px;
    right: 0px;
    bottom: 5px;
    z-index: -1;
  }
  .header .headerContent .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 660px) {
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .header .headerContent {
    padding-left: 0;
    padding-right: 0;
  }
  .header .headerContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .header .headerContent .cardDiv .input {
    margin-bottom: 1rem;
  }
  .header .headerContent .cardDiv .searchOptions {
    bottom: -1rem;
    padding: 8px 1rem;
  }
  .header .headerContent .cardDiv .searchOptions span {
    font-size: 17px;
  }
}/*# sourceMappingURL=header.css.map */
/* Colors */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.quickContact .floating-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: hsl(199, 100%, 33%);
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  text-decoration: none;
  font-size: 24px;
  transition: background-color 0.3s;
}
.quickContact .floating-button.whatsapp {
  bottom: 100px;
  background-color: #25d366;
}
.quickContact .whatsapp {
  font-size: 30px;
}
.quickContact .floating-button.whatsapp:hover, .quickContact .floating-button:hover {
  background-color: hsl(187, 85%, 43%);
  background-color: var(--secondary-color);
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: hsl(0, 0%, 93%);
  background-color: var(--white-color-deam);
  width: 100%;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
}
.header .logo {
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.header .logo .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
}
@media screen and (max-width: 768px) {
  .header .navBar {
    position: absolute;
    background: hsl(0, 0%, 93%);
    background: var(--white-color-deam);
    height: -webkit-max-content;
    height: max-content;
    width: 80%;
    border-radius: 1rem;
    top: -500rem;
    left: 50%;
    padding: 1rem;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 0.5s ease-in-out;
  }
  .header .navBar .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 0.5rem 0;
  }
  .header .navBar .navLists .navItem {
    padding: 0.5rem 0;
  }
  .header .navBar .navLists .navItem .navLink {
    color: hsl(240, 4%, 36%);
    color: var(--text-color);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .header .navBar .navLists .navItem .navLink:hover {
    color: hsl(199, 100%, 33%);
    color: var(--primary-color);
  }
  .header .navBar .navLists .btn {
    margin-top: 1rem;
  }
  .header .navBar .navLists .btn a {
    font-weight: 600;
    color: #FFF;
    color: var(--white-color);
  }
  .header .navBar .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: hsl(199, 100%, 33%);
    color: var(--primary-color);
  }
  .header .navBar .closeNavbar:hover {
    color: hsl(187, 85%, 43%);
    color: var(--secondary-color);
  }
  .header .activeNavbar {
    top: 7rem;
  }
}
.header .toggleNavbar .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}
.header .toggleNavbar:hover.icon {
  color: hsl(187, 85%, 43%);
  color: var(--secondary-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 769px) {
  .toggleNavbar, .closeNavbar {
    display: none;
  }
  .header {
    padding: 1.5rem 2rem;
  }
  .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .header .navBar .navLists .navItem .navLink {
    color: hsl(240, 4%, 36%);
    color: var(--text-color);
    padding: 0 0.45rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .header .navBar .navLists .navItem .navLink:hover {
    color: hsl(199, 100%, 33%);
    color: var(--primary-color);
  }
  .header .navBar .navLists .btn {
    margin-left: 1rem;
  }
  .header .navBar .navLists .btn a {
    color: #FFF;
    color: var(--white-color);
    font-weight: 500;
  }
}
@media screen and (min-width: 960px) {
  .header .navBar .navLists .navItem {
    padding: 0 0.7rem;
  }
}
@media screen and (min-width: 1024px) {
  .header {
    padding: 1rem 8.5rem;
  }
}/*# sourceMappingURL=navbar.css.map */
:root {
  --primary-color: hsl(199, 100%, 33%);
  --secondary-color: hsl(187, 85%, 43%);
  --gradient-color: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --white-color: #FFF;
  --black-color: hsl(201, 33%, 16%);
  --text-color: hsl(240, 4%, 36%);
  --white-color-deam: hsl(0, 0%, 93%);
  --gray-text: rgb(190, 190, 190);
  --input-color: rgb(229, 229, 229);
  --body-color: rgb(240, 240, 246);
  --card-background: rgb(225, 225, 235);
}

.footer {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.footer .videoDiv {
  position: absolute;
  height: 100%;
  width: 100%;
}
.footer .videoDiv video {
  height: 100%;
  object-fit: cover;
}
.footer .videoDiv::after {
  content: "";
  position: absolute;
  background: rgba(10, 84, 107, 0.307);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: multiply;
}
.footer .secContent {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 2rem intial;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  z-index: 100;
}
.footer .secContent .contactDiv {
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.footer .secContent .contactDiv .text small {
  font-size: 13px;
  font-weight: 400;
  color: #FFF;
  color: var(--white-color);
}
.footer .secContent .contactDiv .text h2 {
  color: #FFF;
  color: var(--white-color);
  font-size: 1.8rem;
}
.footer .secContent .contactDiv .inputDiv {
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
}
.footer .secContent .contactDiv .inputDiv input {
  width: 100%;
  padding: 0.5rem 0.6rem;
  border-radius: 3rem;
  border: 1px solid #FFF;
  border: 1px solid var(--white-color);
  outline: none;
  background: transparent;
  color: #FFF;
  color: var(--white-color);
}
.footer .secContent .contactDiv .inputDiv input::-webkit-input-placeholder {
  color: #FFF;
  color: var(--white-color);
  opacity: 0.5;
}
.footer .secContent .contactDiv .inputDiv input::placeholder {
  color: #FFF;
  color: var(--white-color);
  opacity: 0.5;
}
.footer .secContent .contactDiv .inputDiv .btn {
  width: 100%;
  justify-content: center;
  color: #FFF;
  color: var(--white-color);
  font-weight: 500;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.footer .secContent .contactDiv .inputDiv .btn .icon {
  font-size: 18px;
}
.footer .secContent .footerCard {
  position: relative;
  padding: 1rem 1rem 4rem;
  grid-gap: 2rem;
  gap: 2rem;
  border-radius: 1rem;
  background: rgb(240, 240, 246);
  background: var(--body-color);
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.footer .secContent .footerCard .footerIntro {
  flex-basis: 50%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.footer .secContent .footerCard .logoDiv .logo {
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  font-weight: 600;
}
.footer .secContent .footerCard .logoDiv .logo .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
  margin-right: 10px;
}
.footer .secContent .footerCard .footerParagraph {
  font-size: 13px;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
}
.footer .secContent .footerCard .footerSocials {
  -moz-column-gap: 0.5rem;
       grid-column-gap: 0.5rem;
       -webkit-column-gap: 0.5rem;
               column-gap: 0.5rem;
}
.footer .secContent .footerCard .footerSocials .icon {
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  font-size: 20px;
}
.footer .secContent .footerCard .footerSocials .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
}
.footer .secContent .footerCard .footerLinks {
  flex-basis: 50%;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}
.footer .secContent .footerCard .footerLinks .linkGroup .groupTitle {
  display: block;
  font-weight: 600;
  color: hsl(201, 33%, 16%);
  color: var(--black-color);
  margin-bottom: 0.5rem;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList {
  font-size: 15px;
  color: hsl(240, 4%, 36%);
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList .icon {
  font-size: 15px;
  color: rgb(190, 190, 190);
  color: var(--gray-text);
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover {
  color: hsl(199, 100%, 33%);
  color: var(--primary-color);
  -webkit-transform: translate(7px);
          transform: translate(7px);
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover .icon {
  color: hsl(187, 85%, 43%);
  color: var(--secondary-color);
}
.footer .secContent .footerCard .footerDiv {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: hsl(187, 85%, 43%);
  background: var(--secondary-color);
  padding: 0.5rem 2rem;
}
.footer .secContent .footerCard .footerDiv small {
  font-size: 10px;
  color: #FFF;
  color: var(--white-color);
}

/* ------------------ MEDIA QURIES ------------------ */
@media screen and (min-width: 500px) {
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .footerCard .footerDiv {
    justify-content: space-between;
    flex-direction: row;
  }
  .footer .secContent .footerCard .footerDiv small {
    font-size: 14px;
    color: #FFF;
    color: var(--white-color);
  }
}
@media screen and (min-width: 660px) {
  .footer .secContent .contactDiv {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .secContent .contactDiv .inputDiv {
    width: -webkit-max-content;
    width: max-content;
    flex-direction: row;
    justify-content: flex-start;
  }
  .footer .secContent .contactDiv .inputDiv input {
    width: 60%;
  }
  .footer .secContent .contactDiv .inputDiv .btn {
    width: -webkit-max-content;
    width: max-content;
  }
}
@media screen and (min-width: 840px) {
  .footer {
    padding: 5rem 0;
  }
  .footer .secContent .footerCard {
    flex-direction: row;
  }
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer .secContent .footerCard {
    padding: 3rem 1rem;
  }
  .footer .secContent .footerCard .footerIntro .footerParagraph {
    font-size: 15px;
    color: hsl(240, 4%, 36%);
    color: var(--text-color);
  }
  .footer .secContent .footerCard .footerIntro .footerSocials {
    -moz-column-gap: 0.5rem;
         grid-column-gap: 0.5rem;
         -webkit-column-gap: 0.5rem;
                 column-gap: 0.5rem;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon {
    color: hsl(240, 4%, 36%);
    color: var(--text-color);
    font-size: 25px;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon:hover {
    color: hsl(199, 100%, 33%);
    color: var(--primary-color);
  }
  .footer .secContent .footerCard .footerLinks .linkGroup .footerList {
    font-size: 16px;
    padding: 5px 0;
  }
}/*# sourceMappingURL=footer.css.map */
